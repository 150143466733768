import { EXPERIMENT_REMOVE_INVALID_PAGE_IN_ADI } from '@wix/communities-blog-experiments';
import { INVALID_WIDGET_ID } from '../constants/widgets';
import experiments from './experiments';
import { getComponnetRef } from './magic-migration/sdk-utils';

export async function removeInvalidPageInADI({ sdk, appToken, isADI }) {
  if (!experiments.isEnabled(EXPERIMENT_REMOVE_INVALID_PAGE_IN_ADI)) {
    return;
  }

  if (!isADI) {
    return;
  }

  const componentRef = await getComponnetRef(sdk, INVALID_WIDGET_ID);
  if (!componentRef) {
    return;
  }

  const pageRef = await sdk.document.components.getPage(appToken, { componentRef });
  if (!pageRef) {
    return;
  }

  await sdk.document.pages.remove(appToken, { pageRef });
}
